import axios from "axios";
import { BM_API_URL } from "../utils/api";

const get_login_url = `${BM_API_URL}/msmarket/oauth`; 
const post_login_url = `${BM_API_URL}/msmarket/auth`; 

export const getMSAuthUrl = async() => {
  try {
    const response = await axios.get(
      get_login_url,
      {withCredentials: true});
      localStorage.setItem("msAuth", JSON.stringify(response.data));          
  } catch (error) {
    console.log(error)
    
  }
};

export const loginMsUser = async() => {
  const data = localStorage.getItem("msAuth");
  if (data) {
    const { token } = JSON.parse(data);
    try {
      const response:any = await axios.post(
        post_login_url,
        {token : token},
        {withCredentials: true}
      );
       response.data["token_date"] = new Date().getTime();
       localStorage.setItem("activeUser", JSON.stringify(response.data));    
    } catch (error) {
      console.log(error)      
    }  
  }

};

